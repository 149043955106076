import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import EventBlock from "../components/EventBlock/EventBlock";
import "../styles/tour.scss";
import Loading from "../images/loading.gif";
import Footer from "../components/Footer/Footer";

import { Helmet } from "react-helmet";

const Tour = ({ data, location }) => {
  const [loading, setLoading] = useState(false);
  const [gigData, setGigData] = useState([]);
  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    setLoading(true);

    fetch(
      `https://rest.bandsintown.com/artists/id_15495391/events/?app_id=675e9674c95fdeb12a2b32718624358d`
    )
      .then(function (res) {
        return res.json();
      })
      .then((res) => {
        setGigData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error Calling Events API", err);
        setLoading(false);
        setFetchError(true);
      });
  }, []);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Boy Golden | Tour Dates</title>
        <meta
          name="description"
          content="Catch Boy Golden live on tour! Check out our updated tour dates and locations for an unforgettable musical experience. Don't miss your chance to see a live show – get your tickets now!"
        />
      </Helmet>

      <div className="cal-page-container  ">
        <Navbar />
        <div className="cal-flexBox " style={{ minHeight: "100vh" }}>
          <div className=" cal-container tacky-border2  bg-secondary">
            <h1 className=" center-text txt-black">Upcoming Shows </h1>
            {gigData &&
              !loading &&
              gigData?.map((show, i) => {
                return <EventBlock event={show} key={`event-${i + 1}`} />;
              })}

            {loading && !fetchError && !gigData.length && (
              <div className="center-text">
                <h5 className="txt-black">Loading shows...</h5>
                <img className="faces" src={Loading} alt="loading gif"></img>
              </div>
            )}

            {fetchError && !loading && (
              <h6 className="txt-black center-text txt-shadow2">
                <a
                  href="https://www.bandsintown.com/a/15495391-boy-golden"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See Upcoming Performances Here!
                </a>
              </h6>
            )}

            {/* OLD BANDSINTOWN CUSTOM STYLING WHEN IMPORTING SCRIPT AND WIDGET
            
            <a
              className="bit-widget-initializer"
              data-artist-name="id_15495391"
              data-background-color="#ffffff"
              data-separator-color="#e7e7e7"
              data-text-color="#1bc0d2"
              data-font="arial"
              data-auto-style="false"
              data-display-local-dates="true"
              data-local-dates-position="tab"
              data-display-past-dates="true"
              data-display-details="false"
              data-display-lineup="false"
              data-display-start-time="false"
              data-social-share-icon="true"
              data-display-limit="all"
              data-date-format="MMM. D, YYYY"
              data-date-orientation="horizontal"
              data-date-border-color="#1bc0d2"
              data-date-border-width="1px"
              data-date-border-radius="10px"
              data-event-ticket-cta-size="medium"
              data-event-ticket-text="TICKETS"
              data-event-ticket-icon="false"
              data-event-ticket-cta-text-color="#FFFFFF"
              data-event-ticket-cta-bg-color="#1bc0d2"
              data-event-ticket-cta-border-color="#1bc0d2"
              data-event-ticket-cta-border-width="0px"
              data-event-ticket-cta-border-radius="4px"
              data-sold-out-button-text-color="#FFFFFF"
              data-sold-out-button-background-color="#9B9B9B"
              data-sold-out-button-border-color="#1bc0d2"
              data-sold-out-button-clickable="true"
              data-event-rsvp-position="left"
              data-event-rsvp-cta-size="medium"
              data-event-rsvp-text="SET REMINDER"
              data-event-rsvp-icon="false"
              data-event-rsvp-cta-text-color="#ffffff"
              data-event-rsvp-cta-bg-color="#efc473"
              data-event-rsvp-cta-border-color="#efc473"
              data-event-rsvp-cta-border-width="1px"
              data-event-rsvp-cta-border-radius="4px"
              data-follow-section-position="top"
              data-follow-section-alignment="center"
              data-follow-section-header-text="Get updates on new shows, new music, and more."
              data-follow-section-cta-size="medium"
              data-follow-section-cta-text="FOLLOW"
              data-follow-section-cta-icon="true"
              data-follow-section-cta-text-color="#FFFFFF"
              data-follow-section-cta-bg-color="#e89fd0"
              data-follow-section-cta-border-color="#e89fd0"
              data-follow-section-cta-border-width="0px"
              data-follow-section-cta-border-radius="4px"
              data-play-my-city-position="bottom"
              data-play-my-city-alignment="Center"
              data-play-my-city-header-text="Don’t see a show near you?"
              data-play-my-city-cta-size="medium"
              data-play-my-city-cta-text="REQUEST A SHOW"
              data-play-my-city-cta-icon="true"
              data-play-my-city-cta-text-color="#FFFFFF"
              data-play-my-city-cta-bg-color="#e89fd0"
              data-play-my-city-cta-border-color="#e89fd0"
              data-play-my-city-cta-border-width="0px"
              data-play-my-city-cta-border-radius="4px"
              data-language="en"
              data-widget-width=""
              data-div-id="bandsintown-gatsby-widget"
              data-app-id=""
              data-affil-code="undefined"
              data-bit-logo-position="bottomRight"
              data-bit-logo-color="#CCCCCC"
            ></a> */}

            {/* OLD GOOGLE CALENDAR VERSION
            <iframe
              title="Church Calendar"
              className="cal-mobile"
              src="https://calendar.google.com/calendar/embed?src=churchofbetterdaze%40gmail.com&ctz=America%2FWinnipeg"
            ></iframe> */}

            <br />
            <h6 className="center-text txt-black">
              <a
                href="https://laylo.com/boygolden"
                target="_blank"
                className="txt-black"
              >
                Get notified about exclusive content, merch, and event drops!{" "}
              </a>
            </h6>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Tour;
