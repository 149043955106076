import React from "react";
import "../../styles/tour.scss";

const EventBlock = ({ event }) => {
  const formatDate = (dateTime) => {
    const monthArray = [
      "Jan.",
      "Feb.",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug.",
      "Sept.",
      "Oct.",
      "Nov.",
      "Dec.",
    ];

    const nth = function (d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    const date = new Date(dateTime);
    const month = monthArray[date.getMonth()];
    const day = dateTime.split("-")[2].slice(0, 2);
    const year = date.getFullYear();

    return `${month} ${+day[0] === 0 ? day.slice(1) : day}${nth(+day)} ${year}`;
  };

  return (
    <>
      <div className="event-listing">
        <a href={event.url} rel="noopener" target="_blank">
          <h5 className="txt-black venue">{event.venue.name}</h5>
          {event?.lineup.length > 1 && event?.lineup[1] === "The Sheepdogs" && (
            <h5 className="txt-secondary subtext-sm">+ {event.lineup[1]}</h5>
          )}
        </a>
        <h5 className="txt-black subtext">{formatDate(event.datetime)}</h5>
        <h5 className="txt-black subtext">
          {event.venue.city} - {event.venue.region}
        </h5>
        {event?.offers.length > 0 &&
        event?.offers[0].type === "Tickets" &&
        event?.offers[0].url ? (
          <a href={event.offers[0].url} rel="noopener" target="_blank">
            <button className="event-btn">Tickets</button>
          </a>
        ) : (
          <div className="event-btn" style={{ visibility: "hidden" }}>
            Tickets
          </div>
        )}
      </div>
    </>
  );
};

export default EventBlock;
